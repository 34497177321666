import React from 'react';
import { useOptions } from '../../helpers/OptionsContext/OptionsContext';

import styles from './contactLink.module.scss';

const ContactLink = ({ className = '' }) => {
   const {
      admin: { life, email, mts },
      text,
      image,
      site,
   } = useOptions();

   return (
      <div className={`footer__link ${className} ${styles.contact}`}>
         <a className={`${styles.icon}`} href={`https://${text.linkPromNut.link}/`}>
            <img className={`${styles.icon}`} src={image.prom} alt={site.contact?.mail} />
         </a>

         <a className={`${styles.icon}`} href={`tel:+${life}`}>
            <img className={`${styles.icon}`} src={image.life} alt="life" />
         </a>

         <a className={`${styles.icon}`} href={`tel:+${mts}`}>
            <img className={`${styles.icon}`} src={image.mts} alt="mts" />
         </a>

         <a className={`${styles.icon}`} href={`viber://chat?number=%2B${life}`}>
            <img className={`${styles.icon}`} src={image.viber} alt="viber" />
         </a>

         <a className={`${styles.icon}`} href={`mailto:${email}`}>
            <img className={`${styles.icon}`} src={image.email} alt={site.contact?.mail} />
         </a>
      </div>
   );
};

export default ContactLink;
