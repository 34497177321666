'use strick';
import React from 'react';
import { useOptions } from '../../helpers/OptionsContext/OptionsContext';
import Contact from './Contact';

import Navigation from './Navigation';
import { ToggleLeng } from '../common';

const Header = () => {
   const { delivery, image } = useOptions();

   const banner = {
      background: `url('${image.banner}')`,
      backgroundSize: 'cover',
   };
   return (
      <header id="header" style={banner}>
         <div className="container">
            <Contact />
         </div>
         <ToggleLeng />
         <Navigation delivery={delivery} />
      </header>
   );
};

export default Header;
