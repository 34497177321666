'use strick';
import React from 'react';
import { useOptions } from '../../../helpers/OptionsContext/OptionsContext';

import styles from './contact.module.scss';

const Contact = () => {
   const {
      admin: { life, mts, weekdays, weekend },
      site: { header },
   } = useOptions();

   return (
      <div className={`contact ${styles.contact}`}>
         <a className="contact_phone" href={`tel:+${life}`}>
            {`+${life}`}
         </a>
         <a className="contact_phone" href={`tel:+${mts}`}>
            {`+${mts}`}
         </a>
         <span className="contact-text">{header.contact.schedule}</span>
         <ul className="contact-time">
            <li>
               {header.contact.workday}
               <span>
                  {weekdays.before} - {weekdays.after}
               </span>
            </li>
            <li>
               {header.contact.weekend}
               <span>
                  {weekend.before} - {weekend.after}
               </span>
            </li>
         </ul>
      </div>
   );
};

export default Contact;
