import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PublicRoute({
  component: Component,
  restricted,
  ...routeProps
}) {
  return <Route {...routeProps} render={props => <Component {...props} />} />;
}
