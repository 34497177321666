import React from 'react';
import { useOptions } from '../../helpers/OptionsContext/OptionsContext';
import ContactLink from '../ContactLink';

import styles from './footer.module.scss';

const Footer = () => {
   const { admin, text, site } = useOptions();

   const { address } = admin;
   return (
      <footer id="footer">
         <div className={styles.container}>
            <div className={styles.about}>
               <h3 className={styles.title}>{site.footer.about}</h3>
               <p className={styles.text}>{text.about}</p>
               <p className={styles.text}>{text.veloCity}</p>
               <div className={styles.list_site}>
                  <p className={styles.text}>
                     <a className={`${styles.link}`} href={`https://${text.linkVeloCity}/`}>
                        {text.linkVeloCity}
                     </a>
                  </p>
                  <p className={styles.text}>
                     <a className={`${styles.link}`} href={`https://${text.linkPromNut.link}/`}>
                        {text.linkPromNut.text}
                     </a>
                  </p>
               </div>
            </div>
            <div className="footer__contact">
               <h3 className={styles.title}>{site.footer.contact}</h3>
               <address className={`${styles.address} `}>{address}</address>
               <ContactLink />
            </div>
         </div>
      </footer>
   );
};

export default Footer;
