import React from 'react';
import ReactDOM from 'react-dom';

import './scss/main.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { OptionsProvider } from './helpers/OptionsContext/OptionsContext';

ReactDOM.render(
   <OptionsProvider>
      <React.StrictMode>
         <App />
      </React.StrictMode>
   </OptionsProvider>,
   document.getElementById('root'),
);

reportWebVitals();

