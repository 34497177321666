import defaultOptions from './def.options.json';
import ruOptions from './ru.options.json';
import uaOptions from './ua.options.json';
import nutsOptions from './nuts.options.json';

/*** List of languages.*/
export const languages = {
   ru: 'ru',
   ua: 'ua',
};

/*** Config func for site.*/
export const config = (lang = languages.ua) => {
   const options = lang === languages.ua ? uaOptions : ruOptions;
   return {
      nuts: nutsOptions.nuts,

      params: {
         ...defaultOptions.params,
         ...options.params,
      },

      text: {
         ...defaultOptions.text,
         ...options.text,
      },

      admin: {
         ...defaultOptions.admin,
         ...options.admin,
      },

      image: {
         ...defaultOptions.image,
      },

      delivery: options.delivery,
      site: {
         ...options.site,
      },
   };
};
