'use strick';
import React from 'react';

import { useOptions } from '../../../helpers/OptionsContext/OptionsContext';
import Link from '../../Link';

const Navigation = () => {
   const {
      site: { header },
   } = useOptions();

   return (
      <nav className="nav">
         <ul className="nav-container">
            <Link to={'/'} name={header.navigation.main}></Link>
            <Link to={'/delivery'} name={header.navigation.delivery}></Link>
         </ul>
      </nav>
   );
};

export default Navigation;
