'use strick';

import React from 'react';
import { useOptions } from '../../../helpers/OptionsContext/OptionsContext';

import styles from './toggleLeng.module.scss';

export const ToggleLeng = () => {
   const { useToggleLeng, languages } = useOptions();

   return (
      <div className={styles.wrap}>
         <button className={styles.btnUa} onClick={e => useToggleLeng(languages.ua)}>
            {languages.ua}
         </button>

         <button className={styles.btnRu} onClick={e => useToggleLeng(languages.ru)}>
            {languages.ru}
         </button>
      </div>
   );
};
