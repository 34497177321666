'use strick';
import React, { useContext, useState, useEffect } from 'react';

import { config, languages } from '../../config';

const OptionsContext = React.createContext({ ...config(languages.ua) });

export const useOptions = () => {
   return useContext(OptionsContext);
};

export const OptionsProvider = ({ children }) => {
   const [opt, useOpt] = useState(config(languages.ua));
   const [toggleLeng, useToggleLeng] = useState(languages.ua);

   useEffect(() => {
      if (toggleLeng === languages.ua) {
         useOpt(() => config(languages.ua));
      }
      if (toggleLeng === languages.ru) {
         useOpt(() => config(languages.ru));
      }
   }, [toggleLeng]);

   return (
      <OptionsContext.Provider
         value={{
            ...opt,
            toggleLeng,
            useToggleLeng,
            languages,
         }}
      >
         {children}
      </OptionsContext.Provider>
   );
};
