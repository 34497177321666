'use strick';
import React from 'react';
import { useOptions } from '../../helpers/OptionsContext/OptionsContext';

import { container, img } from './loading.module.scss';
const Loading = () => {
   const {
      image,
      site: { loader },
   } = useOptions();

   return (
      <div className={container}>
         <img className={img} src={image.loading} alt={loader.alt} />
      </div>
   );
};

export default Loading;
