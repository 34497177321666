/* eslint-disable import/no-anonymous-default-export */
import { lazy } from 'react';

export default [
  {
    path: '/',
    label: 'Стальная Гайка',
    exact: true,
    component: lazy(() =>
      import(
        '../components/views/HomePage' /* webpackChunkName: "home-page" */
      ),
    ),
    private: false,
    restricted: true,
  },
  {
    path: '/delivery',
    label: 'Способы Доставки',
    exact: true,
    component: lazy(() =>
      import(
        '../components/views/DeliveryPage' /* webpackChunkName: "delivery-page" */
      ),
    ),
    private: false,
    restricted: true,
  }
];
