import React, { Suspense } from 'react';

import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import routes from './routes/routes';

import PublicRoute from './components/Routes/PublicRoute';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loading from './components/Loading';

import { config } from './config';

function App() {
   const opt = config();

   return (
      <>
         <Router>
            <div className="wrapper">
               <Header options={opt} />
               <Suspense fallback={<Loading />}>
                  <Switch>
                     {routes.map(route => (
                        <PublicRoute key={route.path} {...route} />
                     ))}
                     <Redirect to="/" />
                  </Switch>
               </Suspense>
               <Footer />
            </div>
         </Router>
      </>
   );
}

export default App;

